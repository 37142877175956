import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom/client'

import { useLocalStorage } from 'react-use'

import ReactMarkdown from 'react-markdown'

import {
  CssBaseline,
  ThemeProvider,
  Container,
  Button,
  List,
  ListItemText,
  Typography,
  ListItemAvatar,
  Skeleton,
  Paper,
  Grid,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from '@mui/material'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'

import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'

import IconButton from '@mui/material/IconButton'

import { endpoints } from './endpoints'
import { theme } from './theme'

import {
  StyledAvatar,
  StyledListItem,
  StyledLogo,
  StyledTextField,
  StyledThreadId
} from './styles'

const root = ReactDOM.createRoot(document.getElementById('root'))

const App = () => {
  const [currentResponse, setCurrentResponse] = useState('')

  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const [messages, setMessages] = useState([])
  const [open, setOpen] = React.useState(false)

  const [errorMessage, setErrorMessage] = useState('')
  const [userInput, setUserInput] = useLocalStorage(
    'openAI-user-message',
    'Tell me about your products'
  )
  const [loading, setLoading] = useState(false)

  const messageEndRef = useRef(null)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleKeyDown = (event) => {
    if (
      ((event.ctrlKey || event.metaKey) && event.key === 'Enter') ||
      event.key === 'Enter'
    ) {
      sendMessage()
    }
  }

  const [thread, setThread, removeThread] = useLocalStorage(
    'openAI-thread-id',
    ''
  )

  const fetchThread = async () => {
    console.log('Creating a new thread...')

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers
    }

    const response = await fetch(endpoints.thread, requestOptions)

    if (!response.ok) {
      return null
    }

    try {
      const threadRequest = await response.json()

      setThread(threadRequest.threadId)
    } catch (error) {
      throw new Error(`Failed to parse JSON from response of ${URL}`)
    }
  }

  const createThread = async () => {
    if (thread !== '') {
      return thread
    }
    fetchThread()
  }

  const sendMessage = async () => {
    if (userInput.trim() === '') return

    const headers = new Headers()
    headers.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify({ threadId: thread, message: userInput.trim() })
    }

    setLoading(true)
    setCurrentResponse('')

    const previousMessages = [...messages]

    previousMessages.push({
      role: 'user',
      content: userInput.trim()
    })

    setMessages(previousMessages)
    setUserInput('')
    try {
      const response = await fetch(endpoints.message, requestOptions)
      setUserInput('')
      const reader = response.body.getReader()
      const decoder = new TextDecoder('utf-8')
      let done = false

      while (!done) {
        const { value, done: doneReading } = await reader.read()
        done = doneReading
        const chunk = decoder.decode(value, { stream: !done })
        setCurrentResponse((prev) => {
          return prev.replace(/【[^】]+】/g, '') + chunk
        })
      }

      if (done === true) {
        getMessages()
      }
    } catch (error) {
      console.error('Error running assistant:', error)
    } finally {
      setLoading(false)
    }

    setUserInput('')
  }

  const getMessages = async () => {
    if (thread !== '') {
      try {
        console.log('Getting messages for thread: ' + thread)

        const headers = new Headers()
        headers.append('Content-Type', 'application/json')

        const requestOptions = {
          method: 'POST',
          headers,
          body: JSON.stringify({ threadId: thread })
        }

        const response = await fetch(endpoints.messages, requestOptions)

        if (!response.ok) {
          return null
        }

        try {
          const messages = await response.json()

          setMessages(messages)
          setLoading(false)
        } catch (error) {
          throw new Error(`Failed to parse JSON from response of ${URL}`)
        }
      } catch (error) {}
    }
  }

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView(false, {
      behavior: 'smooth'
    })
  }

  const resetChat = () => {
    fetchThread()
    setMessages([])
    setUserInput('')
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAccept = () => {
    setOpen(false)
    resetChat()
  }

  const Pages = ({ mobile }) => {
    return (
      <>
        <Button
          sx={{
            color: mobile === true ? '#000' : '#fff',
            fontSize: mobile === true ? '14px' : '16px',
            marginRight: '20px',
            marginBottom: mobile === true ? '20px' : 0,
            alignSelf: mobile === true ? 'flex-start' : 'center',
            '&:hover': {
              color: '#824DEB'
            }
          }}
          href="https://nekspine.com/"
        >
          Home
        </Button>
        <Button
          sx={{
            color: mobile === true ? '#000' : '#fff',
            fontSize: mobile === true ? '14px' : '16px',
            marginRight: '20px',
            marginBottom: mobile === true ? '20px' : 0,
            alignSelf: mobile === true ? 'flex-start' : 'center',
            '&:hover': {
              color: '#824DEB'
            }
          }}
          href="https://nekspine.com/solutions/"
        >
          Solutions
        </Button>
        <Button
          sx={{
            color: mobile === true ? '#000' : '#fff',
            fontSize: mobile === true ? '14px' : '16px',
            marginRight: '20px',
            marginBottom: mobile === true ? '20px' : 0,
            alignSelf: mobile === true ? 'flex-start' : 'center',
            '&:hover': {
              color: '#824DEB'
            }
          }}
          href="https://assistant.nekspine.com/"
        >
          NekSpine&trade; Product Assistant
        </Button>
        <Button
          sx={{
            color: mobile === true ? '#000' : '#fff',
            fontSize: mobile === true ? '14px' : '16px',
            marginRight: '20px',
            marginBottom: mobile === true ? '20px' : 0,
            alignSelf: mobile === true ? 'flex-start' : 'center',
            '&:hover': {
              color: '#824DEB'
            }
          }}
          href="https://nekspine.com/industry-news-events/"
        >
          Industry News/Events
        </Button>
        <Button
          sx={{
            color: mobile === true ? '#000' : '#fff',
            fontSize: mobile === true ? '14px' : '16px',
            marginRight: '20px',
            marginBottom: mobile === true ? '20px' : 0,
            alignSelf: mobile === true ? 'flex-start' : 'center',
            '&:hover': {
              color: '#824DEB'
            }
          }}
          href="https://nekspine.com/contact/"
        >
          Contact
        </Button>
      </>
    )
  }

  useEffect(() => {
    createThread()

    return () => {}
  }, [])

  useEffect(() => {
    getMessages()
    return () => {}
  }, [])

  useEffect(() => {
    scrollToBottom()
  }, [messages, loading, currentResponse])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Container
        style={{
          backgroundColor: 'transparent',
          paddingBottom: '240px'
        }}
      >
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent'
          }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box sx={{ flexGrow: 1, display: { sx: 'flex', lg: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { sx: 'block', lg: 'none' }
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    sx={{
                      padding: '20px 40px'
                    }}
                  >
                    <Pages mobile={true} />
                  </Grid>
                </Menu>
              </Box>

              <Typography
                variant="h5"
                noWrap
                component="a"
                href="https://nekspine.com"
                sx={{
                  mr: 2,
                  display: { sx: 'flex', lg: 'none' },
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none'
                }}
              >
                <StyledLogo
                  src="https://okf.nuu.mybluehost.me/website_e979d7d6/wp-content/uploads/2023/09/nekspine-white-175-1.png"
                  alt="NekSpine Logo"
                />
              </Typography>

              {/* Desktop Navigation */}

              <Typography
                variant="h6"
                noWrap
                component="a"
                href="https://nekspine.com"
                sx={{
                  mr: 2,
                  display: { xs: 'none', lg: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none'
                }}
              >
                <StyledLogo
                  src="https://okf.nuu.mybluehost.me/website_e979d7d6/wp-content/uploads/2023/09/nekspine-white-175-1.png"
                  alt="NekSpine Logo"
                />
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
                <Pages />
              </Box>
            </Toolbar>
          </Container>
        </AppBar>

        <List style={{ marginTop: '20px' }}>
          {messages.length <= 0 && (
            <Typography
              variant="body1"
              align="center"
              style={{ color: '#fff' }}
            >
              <Typography
                variant="h4"
                sx={{
                  marginBottom: '20px'
                }}
              >
                Do you have questions about NekSpine&trade;?
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: '20px'
                }}
              >
                Meet the NekSpine&trade; Product Assistant, an AI-powered tool
                designed to answer almost any question you might have about
                using NekSpine&trade;. Whether you're curious about how it
                prevents back, neck, and shoulder strain, or why it benefits a
                wide range of physicians from cardiologists to dentists, this
                assistant has you covered - even during routine procedures.
              </Typography>
              <Typography variant="h6">
                Start the conversation with a simple “Hello.” Give it a try
              </Typography>
            </Typography>
          )}

          {messages.length > 0 &&
            messages.map((msg, index) => (
              <StyledListItem
                key={index}
                alignItems="flex-start"
                role={msg?.role}
              >
                <ListItemAvatar>
                  <StyledAvatar
                    alt={msg.role}
                    src={
                      msg.role === 'assistant' ? '/cb4645fd.jpg' : '/user.webp'
                    }
                  />
                </ListItemAvatar>

                {msg.role === 'assistant' && (
                  <>
                    <ListItemText
                      secondary={
                        <Chip
                          component="span"
                          label={`Assistant's answer`}
                          variant="filled"
                        />
                      }
                    >
                      <ReactMarkdown>{msg.content}</ReactMarkdown>
                    </ListItemText>
                  </>
                )}

                {msg.role === 'user' && (
                  <>
                    <ListItemText
                      secondary={
                        <Chip
                          component="span"
                          label="Your question"
                          variant="outlined"
                        />
                      }
                    >
                      <ReactMarkdown>{msg.content}</ReactMarkdown>
                    </ListItemText>
                  </>
                )}
              </StyledListItem>
            ))}

          {loading === true && currentResponse === '' && (
            <StyledListItem
              alignItems="flex-start"
              style={{
                margin: '20px 0 0',
                border: 0,
                boxShadow: 'rgba(4, 4, 4, 0.4) 0 4px 12px',
                backgroundColor: '#fff'
              }}
            >
              <>
                <ListItemText
                  secondary={
                    <Chip
                      component="span"
                      label={`Assistant's answer`}
                      variant="filled"
                    />
                  }
                >
                  <Skeleton
                    variant="rectangular"
                    height={22}
                    style={{
                      margin: '10px 0 12px',
                      backgroundColor: '#824DEB'
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height={22}
                    style={{
                      margin: '0 0 12px',
                      backgroundColor: '#824DEB'
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height={22}
                    style={{
                      margin: '0 0 12px',
                      backgroundColor: '#824DEB'
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height={22}
                    style={{
                      margin: '0 0 12px',
                      backgroundColor: '#824DEB'
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height={22}
                    style={{
                      margin: '0 0 12px',
                      backgroundColor: '#824DEB'
                    }}
                  />
                </ListItemText>
              </>
            </StyledListItem>
          )}
        </List>

        {currentResponse !== '' && loading === true && (
          <StyledListItem
            alignItems="flex-start"
            role={'assistant'}
            style={{
              margin: '20px 0 0',
              border: 0,
              boxShadow: 'rgba(4, 4, 4, 0.4) 0 4px 12px',
              backgroundColor: '#824DEB'
            }}
          >
            <ListItemAvatar>
              <StyledAvatar alt={'assistant'} src={'/cb4645fd.jpg'} />
            </ListItemAvatar>

            <ListItemText
              secondary={
                <Chip
                  component="span"
                  label={`Assistant's answer`}
                  variant="filled"
                />
              }
            >
              <ReactMarkdown>{currentResponse}</ReactMarkdown>
            </ListItemText>
          </StyledListItem>
        )}
      </Container>

      <Container style={{ position: 'relative' }}>
        {thread === '' && (
          <Typography variant="body1" align="center">
            Creating a new thread...
          </Typography>
        )}

        <Container
          style={{
            position: 'fixed',
            bottom: '20px',
            left: 0,
            right: 0,
            width: '100%',
            overflow: 'hidden'
          }}
        >
          <Paper
            style={{
              padding: '10px 20px',
              margin: '0',
              borderRadius: '14px',

              backgroundColor: 'rgba(245,245,255,1)',
              border: '1px solid #ddd'
            }}
          >
            {thread !== '' && (
              <StyledThreadId component="p">Thread ID: {thread}</StyledThreadId>
            )}

            <StyledTextField
              fullWidth
              variant="outlined"
              placeholder="Type your message..."
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={handleKeyDown}
              multiline
              maxRows={10}
              error={errorMessage !== ''}
              helperText={errorMessage}
              style={{ backgroundColor: '#fff' }}
            />

            <Grid
              container
              aria-label="Chat Action Buttons"
              style={{
                justifyContent: 'flex-end'
              }}
            >
              <Grid>
                <Button
                  color="info"
                  variant="outlined"
                  onClick={handleClickOpen}
                  style={{ margin: '20px 20px 20px 0' }}
                  disabled={loading}
                >
                  New Chat
                </Button>
              </Grid>
              <Grid>
                <Button
                  onClick={sendMessage}
                  style={{
                    margin: '20px 0 20px 0',
                    backgroundColor: '#824DEB',
                    color: '#fff'
                  }}
                  disabled={loading}
                >
                  {loading && (
                    <CircularProgress
                      size={20}
                      style={{ marginRight: '10px', color: '#fff' }}
                    />
                  )}
                  Send
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Container>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'New Chat?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Are you sure you'd like to create a new chat?"}
            {'All current messages will be lost.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ paddingBottom: '20px', paddingRight: '20px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            color="info"
            variant="contained"
            onClick={handleAccept}
            autoFocus
          >
            Proceed with New Chat
          </Button>
        </DialogActions>
      </Dialog>

      <div ref={messageEndRef} />
    </ThemeProvider>
  )
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
