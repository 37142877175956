export const servers = {
  development: 'http://localhost:4000/api',
  production: process.env.REACT_APP_API_URL
}

export const endpoints = {
  stream:
    process.env.REACT_APP_NODE_ENV === 'development'
      ? servers.development + '/stream'
      : servers.production + '/stream',
  thread:
    process.env.REACT_APP_NODE_ENV === 'development'
      ? servers.development + '/thread'
      : servers.production + '/thread',
  message:
    process.env.REACT_APP_NODE_ENV === 'development'
      ? servers.development + '/message'
      : servers.production + '/message',
  messages:
    process.env.REACT_APP_NODE_ENV === 'development'
      ? servers.development + '/messages'
      : servers.production + '/messages'
}
