import styled from '@emotion/styled'

import { Avatar, ListItem, Typography, TextField } from '@mui/material'

export const StyledLogo = styled.img`
  margin: 10px 40px 0px 0;
  display: block;
  max-width: 140px;
`

export const StyledAvatar = styled(Avatar)`
  border: 1px solid #ccc;
`

export const StyledListItem = styled(ListItem)`
  border: 1px solid #f0f0f0;
  margin-top: 20px;
  overflow: hidden;
  background-color: #824deb;
  color: #fff;

  border: 0;
  border-radius: 20px;
  box-shadow: rgba(4, 4, 4, 0.4) 0 4px 12px;

  a {
    color: #f0f0f0;
  }

  p {
    margin: 0 0 20px;

    &:last-child {
      margin: 0 0 10px;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  .MuiChip-root {
    background-color: rgba(10, 2, 38, 0.4);
    border-radius: 4px;
    padding: 18px 14px;
    margin: 10px 0 0;
    color: #fff;
  }

  ${(props) => {
    if (props.role === 'user') {
      return `
        border-color: #ddd;
        font-weight: 300;

        background-color: #824DEB;
        color: #fff;

        background-color: #F5F5FF;
        color: #824DEB;


        .MuiChip-root {
          background-color: #824DEB;
          color: #fff;
        }
      `
    }
  }}
`

export const StyledThreadId = styled(Typography)`
  color: #999;
  font-size: 12px;
  margin: 10px 0 0;
`

export const StyledTextField = styled(TextField)`
  margin-top: 20px;
`
