import { createTheme, responsiveFontSizes } from '@mui/material/styles'

let theme = createTheme()

theme = createTheme(theme, {
  typography: {
    htmlFontSize: 18,
    fontFamily: ['Lato', 'Roboto', 'Arial', 'sans-serif'].join(',')
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            backgroundColor: '#ff4d54',
            color: '#fff',
            margin: '0 0 0 auto',
            minWidth: '200px',
            display: 'block',

            '> a': {
              color: '#ff4d54',
              display: 'inline-block'
            },

            '> a:visited': {
              color: '#ff4d54'
            },

            '&:disabled': {
              backgroundColor: '#ff4d54',
              color: '#fff',
              opacity: 0.2
            },

            '&:hover': {
              backgroundColor: 'rgb(16, 108, 222)'
            }
          }
        }
      ]
    }
  }
})

theme = responsiveFontSizes(theme)

export { theme }
